body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'ff-dax-pro', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
}
#fallback-site,
.app {
  flex: 1;
  display: flex;
}

.hidden {
  display: none !important;
}

.scrollable{
  overflow-y:scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}
.scrollable::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

@font-face {
  font-family: 'emonabold';
  src: url('/fonts/emona_bold-webfont.woff2') format('woff2'),
    url('/fonts/emona_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'emonabolditalic';
  src: url('/fonts/emona_bolditalic-webfont.woff2') format('woff2'),
    url('/fonts/emona_bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'emonaitalic';
  src: url('/fonts/emona_italic-webfont.woff2') format('woff2'),
    url('/fonts/emona_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'emonaregular';
  src: url('/fonts/emona-webfont.woff2') format('woff2'),
    url('/fonts/emona-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
